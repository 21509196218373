import megaMenu from "../components/megaMenu.js";

const container = document.querySelector("#mega-menu-container")

if(container){
    container.innerHTML = megaMenu
    // container.addEventListener("click", e => {
    //     if(e.target.tagName === "LI") {
    //         e.target.classList.toggle("active")
    //     }
    // })   
}

export default async element => {
    const y = container.getBoundingClientRect().top ;
    element.classList.toggle("active")
    container.querySelector(".mega-menu").style.height = `${window.innerHeight - y}px`
}