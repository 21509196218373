import { fragment, div, a, ul, li, nav, label, input } from 'html-in-javascript'
import { svg, use } from 'svg-in-javascript'

const toggle =  label(
    input({ class: "visually-hidden", type: "checkbox" }),
    svg(use({"xlink:href": "/images/symbols.svg#chevron-down"}))
)

const FI_MENU_PUHELIMET_JA_TARVIKEET = ul(
    li(
        a({href: "/puhelimet-ja-tarvikkeet/puhelimet"}, "Puhelimet"),
        toggle,
        ul(
            li(a({href: "/puhelimet-ja-tarvikkeet/puhelimet/älypuhelimet"}, "Älypuhelimet")),
            li(a({href: "/puhelimet-ja-tarvikkeet/puhelimet/peruspuhelimet"}, "Peruspuhelimet")),
            li(a({href: "/puhelimet-ja-tarvikkeet/puhelimet/kovaan-käyttöön"}, "Kovaan Käyttöön")),
            li(a({href: "/puhelimet-ja-tarvikkeet/puhelimet/helppokäyttöiset-puhelimet"}, "Helppokäyttöiset")),
        )
    ),
    li(
        a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet"}, "Lisätarvikkeet"),
        toggle,
        ul(
            li(a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet/johdot-ja-laturit"}, "Johdot ja Laturit")),
            li(a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet/koriste-ja-suojakuoret"}, "Koriste ja Suojakuoret")),
            li(a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet/kuulokkeet"}, "Kuulokkeet")),
            li(a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet/muistikortit"}, "Muistikortit")),
            li(a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet/näytönsuojat"}, "Näytönsuojat")),
            li(a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet/telakat-ja-adapterit"}, "Telakat ja Adapterit")),
            li(a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet/varavirta"}, "Varavirta")),
            li(a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet/vr-lasit"}, "VR-lasit")),
            li(a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet/popsocketit"}, "Popsocketit")),
            li(a({href: "/puhelimet-ja-tarvikkeet/lisätarvikkeet/maksupäätteet"}, "Maksupäätteet")),                
        )
    ),
    li(
        a({href: "/puhelimet-ja-tarvikkeet/kellot-ja-rannekkeet"}, "Kellot ja rannekkeet"),
        toggle,
        ul(
            li(a({href: "/puhelimet-ja-tarvikkeet/kellot-ja-rannekkeet/esim-kellot"}, "Esim-kellot")),
            li(a({href: "/puhelimet-ja-tarvikkeet/kellot-ja-rannekkeet/lasten-kellopuhelimet"}, "Lasten Kellopuhelimet")),
            li(a({href: "/puhelimet-ja-tarvikkeet/kellot-ja-rannekkeet/apple-watch"}, "Apple Watch")),
            li(a({href: "/puhelimet-ja-tarvikkeet/kellot-ja-rannekkeet/älykellot"}, "Älykellot")),
            li(a({href: "/puhelimet-ja-tarvikkeet/kellot-ja-rannekkeet/urheilukellot"}, "Urheilukellot")),
            li(a({href: "/puhelimet-ja-tarvikkeet/kellot-ja-rannekkeet/aktiivisuusrannekkeet"}, "Aktiivisuusrannekkeet")),
            li(a({href: "/puhelimet-ja-tarvikkeet/kellot-ja-rannekkeet/tarvikkeet"}, "Tarvikkeet"))
        )
    ),
    li(
        a({href: "/puhelimet-ja-tarvikkeet/autotarvikkeet"}, "Autotarvikkeet"),
        toggle,
        ul(
            li(a({href: "/puhelimet-ja-tarvikkeet/autotarvikkeet/autolaturit"}, "Autolaturit")),
            li(a({href: "/puhelimet-ja-tarvikkeet/autotarvikkeet/sähköauton-lataus"}, "Sähköauton Lataus"))
        )
    )
)

const FI_MENU_TIETOTEKNIIKKA = ul(
    li(a({href: "/tietotekniikka/tabletit"}, "Tabletit"), 
        toggle,
        ul(
            li(a({href: "/tietotekniikka/tabletit/android"}, "Android")),
            li(a({href: "/tietotekniikka/tabletit/ipad"}, "iPad")),
            li(a({href: "/tietotekniikka/tabletit/5g-tabletit"}, "5G-tabletit")),
            li(a({href: "/tietotekniikka/tabletit/suojakuoret"}, "Suojakuoret")),
            li(a({href: "/tietotekniikka/tabletit/muistikortit"}, "Muistikortit"))
        )
    ),
    li(a({href: "/tietotekniikka/tietokoneet"}, "Tietokoneet"),
        toggle,
        ul(
            li(a({href: "/tietotekniikka/tietokoneet/läppärit"}, "Läppärit")),
            li(a({href: "/tietotekniikka/tietokoneet/pöytäkoneet"}, "Pöytäkoneet")),
            li(a({href: "/tietotekniikka/tietokoneet/peliläppärit"}, "Peliläppärit")),
            li(a({href: "/tietotekniikka/tietokoneet/pelipöytäkoneet"}, "Pelipöytäkoneet")),
            li(a({href: "/tietotekniikka/tietokoneet/macbookit"}, "Macbookit")),
            li(a({href: "/tietotekniikka/tietokoneet/chromebookit"}, "Chromebookit")),
            li(a({href: "/tietotekniikka/tietokoneet/kannettavat-ylioppilaskirjoituksiin"}, "Kannettavat Ylioppilaskirjoituksiin")),
            li(a({href: "/tietotekniikka/tietokoneet/näytöt"}, "Näytöt")),
        )
    ),
    li(a({href: "/tietotekniikka/lisätarvikkeet"}, "Lisätarvikkeet"), 
        toggle,
        ul(
            li(a({href: "/tietotekniikka/lisätarvikkeet/kantolaukut"}, "Kantolaukut")),
            li(a({href: "/tietotekniikka/lisätarvikkeet/pelihiiret"}, "Pelihiiret")),
            li(a({href: "/tietotekniikka/lisätarvikkeet/pelikuulokkeet"}, "Pelikuulokkeet")),
            li(a({href: "/tietotekniikka/lisätarvikkeet/pelinäppäimistöt"}, "Pelinäppäimistöt")),
            li(a({href: "/tietotekniikka/lisätarvikkeet/pelituolit"}, "Pelituolit")),
            li(a({href: "/tietotekniikka/lisätarvikkeet/pelipöydät"}, "Pelipöydät")),
            li(a({href: "/tietotekniikka/lisätarvikkeet/tulostimet"}, "Tulostimet")),
            li(a({href: "/tietotekniikka/lisätarvikkeet/muut-tarvikkeet"}, "Muut Tarvikkeet"))
        )
    ),
    li(a({href: "/tietotekniikka/nettilaitteet"}, "Nettilaitteet"), 
        toggle,
        ul(
            li(a({href: "/tietotekniikka/nettilaitteet/5g-reitittimet"}, "5G-reitittimet")),
            li(a({href: "/tietotekniikka/nettilaitteet/kodin-verkkolaitteet"}, "Kodin Verkkolaitteet")),
            li(a({href: "/tietotekniikka/nettilaitteet/mobiilireitittimet"}, "Mobiilireitittimet")),
            li(a({href: "/tietotekniikka/nettilaitteet/modeemit"}, "Modeemit")),
            li(a({href: "/tietotekniikka/nettilaitteet/antennit"}, "Antennit")),
        )
    ),
    li(a({href: "/tietotekniikka/dronet-ja-potkulaudat"}, "Drone ja Potkulaudat"),
        toggle,
        ul(
            li(a({href: "/tietotekniikka/dronet-ja-potkulaudat/dronet"}, "Dronet")),
            li(a({href: "/tietotekniikka/dronet-ja-potkulaudat/robotit"}, "Robotit")),
            li(a({href: "/tietotekniikka/dronet-ja-potkulaudat/sähköpotkulaudat"}, "Sähköpotkulaudat")),
            li(a({href: "/tietotekniikka/dronet-ja-potkulaudat/lisävarusteet"}, "Lisävarusteet")),
        )
    ),
)

const FI_MENU_TV = ul(
    li(a({href: "/tv-ja-audio/televisiot/32-tuumaiset"}, "32 Tuumaiset")),
    li(a({href: "/tv-ja-audio/televisiot/43-50-tuumaiset"}, "43-50 Tuumaiset")),
    li(a({href: "/tv-ja-audio/televisiot/55-tuumaiset"}, "55 Tuumaiset")),
    li(a({href: "/tv-ja-audio/televisiot/60-65-tuumaiset"}, "60-65 Tuumaiset")),
    li(a({href: "/tv-ja-audio/televisiot/70-tuumaiset"}, "70 Tuumaiset")),
    li(a({href: "/tv-ja-audio/televisiot/the-frame"}, "The Frame")),
    li(a({href: "/tv-ja-audio/televisiot/projektorit"}, "Projektorit")),
    li(a({href: "/tv-ja-audio/televisiot/mediatoistimet"}, "Mediatoistimet")),
    li(a({href: "/tv-ja-audio/televisiot/kaukosäätimet"}, "Kaukosäätimet")),
    li(a({href: "/tv-ja-audio/televisiot/seinätelineet"}, "Seinätelineet"))
)

const FI_MENU_AUDIO = ul(
    li(a({href: "/tv-ja-audio/televisiot/soundbarit"}, "Soundbarit")),
    li(a({href: "/tv-ja-audio/audio/kaiuttimet"}, "Kaiuttimet")),
    li(a({href: "/tv-ja-audio/audio/kannettavat-kaiuttimet"}, "Kannettavat Kaiuttimet")),
    li(a({href: "/tv-ja-audio/audio/kuulokkeet"}, "Kuulokkeet")),
    li(a({href: "/tv-ja-audio/audio/suoratoistokaiuttimet"}, "Suoratoistokaiuttimet")),
    li(a({href: "/tv-ja-audio/audio/mikrofonit"}, "Mikrofonit")),
    li(a({href: "/tv-ja-audio/audio/levysoittimet"}, "Levysoittimet"))
)

export default ul(
    {class: "mega-menu"},
    li(a({href: "/puhelimet-ja-tarvikkeet"}, "Puhelimet ja Tarvikkeet"), toggle, FI_MENU_PUHELIMET_JA_TARVIKEET),
    li(a({href: "/tietotekniikka"}, "Tietotekniikka"), toggle, FI_MENU_TIETOTEKNIIKKA),
    li(a({href: "/tv-ja-audio/televisiot"}, "TV"), toggle,FI_MENU_TV),
    li(a({href: "/tv-ja-audio/audio"}, "Audio"), toggle, FI_MENU_AUDIO),
    li(a({href: "/pelaaminen"}, "Pelaaminen")),
    li(a({href: "/kodinkoneet"}, "Kodinkoneet")),
    li(a({href: "/hyvinvointi"}, "Hyvinvointi")),
    li(a({href: "/kamerat"}, "Kamerat")),
    li(a({href: "/älykoti"}, "Älykoti")),
    li(a({href: "/outlet"}, "Outlet"))
)